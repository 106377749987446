import React from "react"
import { graphql } from "gatsby"
// @ts-ignore
import { HelmetDatoCms } from "gatsby-source-datocms"
import { DatoCmsContent } from "../layout/DatoCmsContent"
import { Favicon } from "../components/Favicon"

const PageTemplate: React.FC<{ data: any }> = ({
  data: { datoCmsPage: pageData },
}) => {
  if (!pageData) return null
  return (
    <>
      <HelmetDatoCms seo={pageData.seoMetaTags} />
      <Favicon />
      {/*@ts-ignore*/}
      {pageData.content?.map((content, i) => (
        //@ts-ignore
        <DatoCmsContent
          key={content?.id}
          verticalMargins={"default"}
          before={i > 0 ? pageData.content[i - 1] : undefined}
          after={pageData.content[i + 1] || undefined}
          {...content}
        />
      ))}
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query GenericPageData($id: String) {
    datoCmsPage(id: { eq: $id }) {
      id
      ...defaultPageTypeData
    }
  }
`
